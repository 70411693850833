import React from "react";
import { graphql } from "gatsby";
import SalesList from "../../../../components/SalesList";

const Other = props => (
  <SalesList {...props} settings={{ heading: "Helicopters for Sale" }} />
);

export default Other;

export const query = graphql`
  query OtherHelicopterForSaleQuery {
    allForSale: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(helicopters)/[^/]+[.]md$/" }
        frontmatter: { make: { ne: "Schweizer" }, sold: { ne: true } }
      }
    ) {
      edges {
        node {
          ...helicopterFrontmatter
        }
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { showcase: { eq: true } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            make
          }
        }
      }
    }
  }
`;
